<template>
  <div class="w-full pt-5 pb-5">
    <div class="flex">
      <div style="width: 100px">
        <h1 class="text-xl text-left font-extrabold ml-4 mt-3">Leave</h1>
      </div>
    </div>
    <div class="px-3">
      <card class="p-5 mt-6">
        <div class="w-full flex mt-2 mb-6 gap-12">
          <Card class="w-3/6 py-6 px-4">
            <div class="flex justify-between">
              <Icon
                class-name="text-white h-11 "
                icon-name="people-union"
                size="l"
              />
              <div class="flex flex-col h-11">
                <span class="text-xs font-semibold uppercase text-jet"
                  >Pending Requests</span
                >
                <span class="text-lg font-extrabold text-carrotOrange">{{
                  totalPending
                }}</span>
              </div>
              <div class="border bg-white w-0" />
              <div class="flex flex-col h-11">
                <span class="text-xs font-semibold uppercase text-jet"
                  >Approved Requests</span
                >
                <span class="text-lg font-extrabold text-mediumSeaGreen">{{
                  totalApproved
                }}</span>
              </div>
              <div class="border bg-white w-0" />
              <div class="flex flex-col h-11">
                <span class="text-xs font-semibold uppercase text-jet"
                  >Disapproved Requests</span
                >
                <span class="text-lg font-extrabold text-desire">{{
                  totalDisapproved
                }}</span>
              </div>
            </div>
          </Card>
        </div>
        <template v-slot:footer>
          <CardFooter
            buttoncard
            buttontext="New Request"
            module="timeOff"
            printcard
            reloadcard
            @modalRequest="requestModal = true"
            @reload="reload"
          />
        </template>
      </card>
      <card class="p-5 mt-1">
        <div class="flex flex-col">
          <div
            class="w-full flex flex-col"
            v-if="requests.length !== 0 || loading"
          >
            <Table
              :headers="leaveHeader"
              :items="requests"
              class="w-full mt-4 mb-4"
              :has-number="false"
              aria-label="requestTable"
              :loading="loading"
              :pagination-list="metaData"
              @page="handlePage($event)"
              page-sync
              @itemsPerPage="handleItemsPerPage($event)"
            >
              <template v-slot:item="{ item }">
                <div v-if="item.category">
                  <span> {{ item.data.category.name }} </span>
                </div>
                <div v-if="item.requestReason" class="capitalize">
                  <span> {{ item.data.requestReason }} </span>
                </div>
                <div v-else-if="item.daysRequested">
                  <span class="text-sm font-semibold">
                    From
                    {{
                      $DATEFORMAT(
                        new Date(item.data.requestStartDate),
                        "MMMM dd, yyyy"
                      )
                    }}
                    to
                    {{
                      $DATEFORMAT(
                        new Date(item.data.requestEndDate),
                        "MMMM dd, yyyy"
                      )
                    }}
                  </span>
                  <p class="text-sm text-carrotOrange font-semibold">
                    {{ item.data.daysRequested }} Days
                  </p>
                </div>
                <div v-else-if="item.allowanceIsRequested">
                  <p v-if="item.data.allowanceIsRequested" class="text-sm">
                    Yes
                  </p>
                  <p v-else class="text-sm">No</p>
                </div>
                <div
                  v-if="item.delegatee"
                  style="width: 230px"
                  class="pt-3 pb-3"
                >
                  <div class="w-auto flex flex-col">
                    <p class="text-sm font-semibold text-darkPurple">
                      {{ item.data.delegatee.fname }}
                      {{ item.data.delegatee.mname }}
                      {{ item.data.delegatee.lname }}
                    </p>
                  </div>
                </div>
                <div
                  v-if="item.approvalStatus"
                  style="width: 100px"
                  class="p-3"
                  :class="{
                    pending: item.data.approvalStatus === 'pending',
                    approved: item.data.approvalStatus === 'approved',
                    disapproved: item.data.approvalStatus === 'disapproved',
                  }"
                >
                  <div class="w-auto capitalize flex flex-col">
                    <p class="text-sm font-semibold">
                      {{ item.data.approvalStatus }}
                    </p>
                  </div>
                </div>
              </template>
            </Table>
          </div>
          <div
            class="w-full flex flex-col mt-4 p-2 justify-center items-center"
            v-else
          >
            <p class="text-base my-2">
              There are no pending leave requests at the moment.
            </p>
          </div>
        </div>
      </card>
    </div>
    <RightSideBar
      v-if="requestModal"
      @submit="addLeaveRequest"
      @close="closeSideBar"
      :button-class="`bg-dynamicBackBtn text-white rounded`"
      :disabled-button="disableBtn"
    >
      <template v-slot:title>
        <p class="text-darkPurple">Leave Request</p>
      </template>
      <p class="font-semibold text-darkPurple">Create a new leave request</p>
      <p class="mt-4 text-sm text-darkPurple">
        Leave Type <span>( {{ leaveCategoryBalance }} ) </span>
      </p>
      <c-select
        placeholder="--Select--"
        :options="leaveCategory"
        variant="w-full"
        class="mr-2 mt-2"
        v-model="leaveRequest.categoryId"
      />
      <p class="mt-4 text-sm text-darkPurple">Details</p>
      <CTextarea
        placeholder="--Enter--"
        variant="primary"
        class="w-full mt-2"
        value=""
        style="width: 100%"
        :height="100"
        :col="58"
        :input="null"
        v-model="leaveRequest.requestReason"
      />
      <p class="mt-4 text-sm text-darkPurple">Duration</p>
      <div class="w-full flex flex-col">
        <div class="w-full flex mt-4">
          <div class="w-full date-flex">
            <datepicker
              placeholder="--From--"
              input-class="date-input"
              style="width: 100%; outline: none"
              v-model="leaveRequestStartDate"
              @selected="startDatePick($event)"
            />
          </div>
        </div>

        <div class="w-full flex mt-4">
          <div class="w-full date-flex">
            <datepicker
              placeholder="--To--"
              input-class="date-input"
              style="width: 100%; outline: none"
              v-model="leaveRequestEndDate"
              :disabled-dates="disabledEndDates"
            />
          </div>
        </div>
        <span v-if="insufficientLeaveBalance" class="text-red-400"
          >Leave days exceeds leave balance</span
        >
      </div>
      <div
        class="w-11/12 flex mt-3 ml-4 p-2 rounded mb-4 justify-center items-center"
        style="background: #f7f7ff"
      >
        <p class="font-semibold text-xl text-romanSilver">
          {{ leaveRequestDays }}
        </p>
        <p class="text-base text-darkPurple ml-2">Day(s)</p>
      </div>
      <div
        class="w-full border-dashed border my-2"
        style="height: 1px; border-color: #878e99"
      />
      <p class="text-base font-bold text-darkPurple mt-5">Delegatee</p>
      <div
        class="w-1/3 flex my-5 text-flame cursor-pointer"
        @click="employeeModal = true"
      >
        <Icon size="xs" icon-name="icon-arrow-right" />
        <p v-if="!selectedDelegate.fname" class="text-base font-bold ml-3">
          Select a Delegate
        </p>
        <p v-else class="text-base font-bold ml-3">Change Delegate</p>
      </div>
      <card
        class="w-11/12 mt-5 ml-1 p-1 mb-52 h-16"
        v-if="selectedDelegate.fname"
      >
        <div class="flex items-center justify-between w-full p-2">
          <div class="flex items-center">
            <img
              class="mr-2 rounded"
              v-if="selectedDelegate.photo"
              style="width: 40px; height: 40px"
              alt="delegatePhoto"
              :src="selectedDelegate.photo"
            />
            <div
              style="height: 40px; width: 40px; border-radius: 5px"
              class="text-blueCrayola border text-center font-semibold pt-2 mr-2"
              v-else
            >
              {{
                $getInitials(
                  `${selectedDelegate.fname} ${selectedDelegate.lname}`
                )
              }}
            </div>

            <div class="w-auto flex flex-col">
              <p class="text-sm font-semibold text-darkPurple">
                {{ selectedDelegate.fname }} {{ selectedDelegate.lname }}
              </p>
              <p class="text-sm font-semibold text-gray-300">
                {{ selectedDelegate.role }}
              </p>
            </div>
          </div>

          <button
            v-if="selectedDelegate.fname"
            @click="selectedDelegate.fname = null"
            class="text-red-400 font-bold"
          >
            Remove
          </button>
        </div>
      </card>
    </RightSideBar>
    <RightSideBar
      v-if="employeeModal"
      @close="employeeModal = false"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <div class="w-full flex">
          <back-button
            label=""
            @onClick="employeeModal = false"
            variant="secondary"
          />
          <p class="text-darkPurple mt-4">All Employees</p>
        </div>
      </template>
      <template v-slot:subtitle>
        <p class="font-semibold text-darkPurple">Select a delegatee...</p>
        <SearchInput
          placeholder="Search"
          v-model="handleSearch"
          class="search my-5 w-full"
        />
      </template>
      <p class="text-xs font-semibold text-jet">Results</p>
      <div style="height: 100%" v-if="loadingDelegates">
        <loader size="xxs" :loader-image="false" />
      </div>
      <div v-else class="w-full mt-2 mb-8">
        <div
          class="w-full flex-col mt-2 p-3"
          v-for="(delegate, index) in delegates"
          :key="index"
        >
          <div
            class="flex cursor-pointer"
            @click="
              selectDelegate(
                delegate.photo,
                delegate.fname,
                delegate.lname,
                delegate.userDesignation.name,
                delegate.id
              )
            "
          >
            <img
              v-if="delegate.photo"
              class="mr-2 rounded"
              style="width: 40px; height: 40px"
              :src="delegate.photo"
              alt="photo"
            />
            <div
              style="height: 40px; width: 40px; border-radius: 5px"
              class="text-blueCrayola border text-center font-semibold pt-2 mr-2"
              v-else
            >
              {{ $getInitials(`${delegate.fname} ${delegate.lname}`) }}
            </div>
            <div class="w-auto flex flex-col">
              <p class="text-base font-semibold text-darkPurple">
                {{ delegate.fname }} {{ delegate.lname }}
              </p>
              <div class="w-full flex" style="font-size: 10px">
                <p
                  class="font-semibold text-gray-300 uppercase"
                  v-if="delegate.orgFunction"
                >
                  {{ delegate.orgFunction.name }}
                </p>
              </div>
              <div class="w-full flex" style="font-size: 10px">
                <p
                  class="font-semibold text-gray-300 uppercase"
                  v-if="delegate.userDesignation"
                >
                  {{ delegate.userDesignation.name }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="w-11/12 border-dashed border ml-8 mr-3 mt-2"
            style="height: 1px"
          />
        </div>
      </div>
    </RightSideBar>
    <RightSideBar
      v-if="leaveBackModal"
      @submit="submitLeaveBack"
      @close="leaveBackModal = false"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <p class="text-darkPurple">Leave Back Request</p>
      </template>
      <template v-slot:subtitle>
        <p class="font-semibold text-darkPurple">
          Create a leave back request.
        </p>
        <p class="mt-4 w-full font-bold text-desire">Leave Type</p>
        <p class="font-semibold text-darkPurple">
          {{ leaveBackCategory }}
        </p>
        <p class="mt-4 w-full font-bold text-desire">No. of Days</p>
        <p class="font-semibold text-darkPurple">{{ leaveBackDays }} Days</p>
        <p class="mt-4 text-sm text-darkPurple">Duration</p>
        <div class="w-full flex">
          <div class="w-5/12 flex mt-4">
            <div class="date-flex">
              <datepicker
                placeholder="--From--"
                input-class="date-input"
                style="width: 100%; outline: none"
                v-model="leaveBackStartDate"
                :disabled-dates="disabledStartDates"
              />
            </div>
          </div>

          <div class="w-5/12 flex mt-4 ml-4">
            <div class="date-flex">
              <datepicker
                placeholder="--To--"
                input-class="date-input"
                style="width: 100%; outline: none"
                v-model="leaveBackEndDate"
                :disabled-dates="disabledEndDates"
              />
            </div>
          </div>
        </div>
        <p class="mt-4 text-sm text-darkPurple">Justification</p>
        <CTextarea
          placeholder="--Enter--"
          variant="primary"
          class="mt-2 mb-8"
          :value="leaveBack.requestReason"
          :row="10"
          :height="100"
          :col="58"
          :input="null"
          v-model="leaveBack.requestReason"
        />
      </template>
    </RightSideBar>
  </div>
</template>

<script>
import SearchInput from "@scelloo/cloudenly-ui/src/components/search-input";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import CTextarea from "@scelloo/cloudenly-ui/src/components/text-area";
import Datepicker from "vuejs-datepicker";
import Card from "@/components/Card";
import Icon from "@/components/Icon";
import CardFooter from "@/components/CardFooter";
import RightSideBar from "@/components/RightSideBar";

export default {
  name: "Leave",
  components: {
    Table,
    BackButton,
    Datepicker,
    Card,
    Icon,
    CardFooter,
    RightSideBar,
    CSelect,
    CTextarea,
    SearchInput,
  },
  data() {
    return {
      disableBtn: false,
      requestModal: false,
      employeeModal: false,
      leaveBackModal: false,
      loading: true,
      firstWorkingDay: "",
      lastWorkingDay: "",
      handleSearch: "",
      currentTab: "pending",
      metaData: {},
      itemsPerPage: 50,
      numberOfPage: 1,
      leaveHeader: [
        { title: "Leave Type", value: "category" },
        { title: "Details", value: "requestReason" },
        { title: "Duration", value: "daysRequested" },
        { title: "Allowance Requested", value: "allowanceIsRequested" },
        { title: "Delegatee", value: "delegatee" },
        { title: "Status", value: "approvalStatus" },
      ],
      requests: [],
      totalPending: "--",
      totalApproved: "--",
      totalDisapproved: "--",
      leaveCategory: [],
      delegates: [],
      leaveBackStartDate: "",
      leaveBackEndDate: "",
      leaveRequestStartDate: "",
      leaveRequestEndDate: "",
      loadingDelegates: false,
      selectedDelegate: {
        id: "",
        photo: "",
        fname: "",
        lname: "",
        role: "",
      },
      leaveRequest: {
        userId: "",
        orgId: "",
        categoryId: "",
        delegationUserId: "",
        requestStartDate: "",
        requestEndDate: "",
        allowanceIsRequested: true,
        requestReason: "",
      },
      leaveBack: {
        leaveRequestId: "",
        requestStartDate: "",
        requestEndDate: "",
        requestReason: "",
      },
      leaveRequestDays: 0,
      leaveBackDays: 0,
      leaveBalance: 0,
      leaveBackCategory: "",
      disabledEndDates: {
        to: "", // Disable all dates up to specific date
      },
    };
  },
  methods: {
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },
    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },

    queryUpdate() {
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.getLeaveRequest(`?${pageNumber}${itemPage}`);
    },

    startDatePick(value) {
      this.disabledEndDates.to = new Date(value.valueOf());
    },
    getNumberOfDays() {
      this.$_getOrgLeaveDaysLeft({
        userId: this.$AuthUser.id,
        startDate: this.leaveRequest.requestStartDate,
        endDate: this.leaveRequest.requestEndDate,
      }).then((result) => {
        this.leaveRequestDays = result.data.numberOfDays;
        this.leaveBackDays = result.data.numberOfDays;
      });
    },
    submitLeaveBack() {
      const requestPayload = this.leaveBack;
      this.$_applyForLeaveBack(requestPayload)
        .then(() => {
          this.getLeaveRequest("?&page=1&perPage=50");
          this.closeSideBar();
        })
        .catch((err) => {
          throw new Error(err);
        });
    },
    addLeaveRequest() {
      this.disableBtn = true;
      this.leaveRequest.userId = this.$AuthUser.id;
      this.leaveRequest.orgId = this.$orgId;
      const requestPayload = this.leaveRequest;
      this.$_applyForLeave(requestPayload)
        .then(() => {
          this.getLeaveRequest("?&page=1&perPage=50");
          this.disableBtn = false;
          this.$toasted.success("Request submitted successfully", {
            duration: 3000,
          });
          this.closeSideBar();
        })
        .catch((err) => {
          this.disableBtn = false;
          this.$toasted.error(`${err.message}`, { duration: 7000 });
          throw new Error(err);
        });
    },
    async getLeaveRequest(query) {
      this.loading = true;
      const {
        data: { requests, meta },
      } = await this.$_getEmployeeLeaveRequest({
        id: this.$AuthUser.id,
        query,
      });
      this.requests = requests;
      this.metaData = meta;

      // TODO: get these from backend
      this.totalPending = requests.filter(
        (request) => request.approvalStatus === "pending"
      ).length;
      this.totalApproved = requests.filter(
        (request) => request.approvalStatus === "approved"
      ).length;
      this.totalDisapproved = requests.filter(
        (request) => request.approvalStatus === "disapproved"
      ).length;

      this.loading = false;
    },
    reload() {
      this.getLeaveRequest("?&page=1&perPage=50");
    },
    getLeaveCategory() {
      this.$_getEmployeeCategory({ userId: this.$AuthUser.id }).then(
        (result) => {
          this.leaveCategory = result.data.leaveCategories;
        }
      );
    },
    getEmployeeDelegates(query) {
      this.loadingDelegates = true;
      this.$_getAllOrgUsers(query).then((result) => {
        this.delegates = result.data.filter(
          (user) =>
            user.id !== this.$AuthUser.id &&
            user.userDesignation &&
            user.isActive
        );
        this.loadingDelegates = false;
      });
    },
    selectDelegate(photo, fname, lname, designation, id) {
      this.selectedDelegate.id = id;
      this.selectedDelegate.photo = photo;
      this.selectedDelegate.fname = fname;
      this.selectedDelegate.lname = lname;
      this.selectedDelegate.role = designation;
      this.leaveRequest.delegationUserId = id;
      this.employeeModal = false;
      this.handleSearch = "";
    },
    showLeaveBackModal(item) {
      const endDate = new Date(item.requestEndDate);
      endDate.setHours(endDate.getHours() + 8);

      const startDate = new Date(item.requestStartDate);
      startDate.setHours(startDate.getHours() + 8);

      this.leaveBackModal = true;
      this.leaveBack.leaveRequestId = item.id;
      this.leaveBack.requestReason = item.requestReason;
      this.leaveBackStartDate = new Date(startDate);
      this.leaveBackEndDate = new Date(endDate);
      this.leaveBackCategory = item.category.name;
      this.leaveBackDays = item.daysRequested;
    },
    closeSideBar() {
      this.leaveRequest.categoryId = "";
      this.leaveRequest.requestReason = "";
      this.leaveRequestDays = 0;
      this.selectedDelegate.id = "";
      this.selectedDelegate.photo = "";
      this.selectedDelegate.fname = "";
      this.selectedDelegate.lname = "";
      this.selectedDelegate.role = "";
      this.leaveRequest.delegationUserId = "";
      this.leaveRequest.requestStartDate = "";
      this.leaveRequest.requestEndDate = "";
      this.requestModal = false;
      this.employeeModal = false;
      this.leaveBackModal = false;
      this.leaveRequestStartDate = "";
      this.leaveRequestEndDate = "";
    },
    getNumberDays() {
      const setStartDate = this.$DATEFORMAT(
        new Date(this.leaveRequestStartDate),
        "yyyy/MM/dd"
      );
      const setEndDate = this.$DATEFORMAT(
        new Date(this.leaveRequestEndDate),
        "yyyy/MM/dd"
      );

      const firstDate = new Date(`${setStartDate}`);
      const secondDate = new Date(`${setEndDate}`);

      let days = 0;
      const curDate = new Date(firstDate.getTime());
      while (curDate <= secondDate) {
        const dayOfWeek = curDate.getDay();
        if (
          dayOfWeek >= this.firstWorkingDay &&
          dayOfWeek <= this.lastWorkingDay
        )
          days += 1;
        curDate.setDate(curDate.getDate() + 1);
      }
      this.leaveRequestDays = days;
      return false;
    },
    getPaySchedule() {
      const workingDays = [];
      this.$_getPaySchedules().then((result) => {
        const allDays = result.data.data[0].weeklyWorkingDays;
        allDays.filter((days) => {
          if (days.state) {
            workingDays.push(days.value);
          }
          return {};
        });
        const daysLength = workingDays.length;
        const splitDays = workingDays.toString().split(",");
        const firstDay = splitDays[0];
        const lastDay = splitDays[daysLength - 1];
        this.firstWorkingDay = firstDay;
        this.lastWorkingDay = lastDay;
      });
    },
  },
  computed: {
    leaveCategoryBalance() {
      const selectedCategory = this.leaveCategory.find(
        (category) => category.id === this.leaveRequest.categoryId
      );

      if (selectedCategory) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.leaveBalance = selectedCategory.balance;
        return this.leaveBalance === 1
          ? `Balance: ${this.leaveBalance} day`
          : `Balance: ${this.leaveBalance} days`;
      }
      return "Select category to see balance";
    },
    insufficientLeaveBalance() {
      if (this.leaveRequestDays > this.leaveBalance) {
        return true;
      }
      return false;
    },
    hasSubscriptionExpired() {
      return this.$store.getters.hasSubscriptionExpired;
    },
  },
  watch: {
    handleSearch(value) {
      this.getEmployeeDelegates(`search=${value}`);
    },
    leaveBackStartDate(date) {
      this.leaveBack.requestStartDate = this.$DATEFORMAT(
        new Date(date),
        "yyyy-MM-dd"
      );
    },
    leaveBackEndDate(date) {
      this.leaveBack.requestEndDate = this.$DATEFORMAT(
        new Date(date),
        "yyyy-MM-dd"
      );
    },
    leaveRequestStartDate(date) {
      this.leaveRequest.requestStartDate = this.$DATEFORMAT(
        new Date(date),
        "yyyy-MM-dd"
      );
      this.getNumberDays();
    },
    leaveRequestEndDate(date) {
      this.leaveRequest.requestEndDate = this.$DATEFORMAT(
        new Date(date),
        "yyyy-MM-dd"
      );
      this.getNumberDays();
    },
  },
  mounted() {
    this.getLeaveRequest("?&page=1&perPage=50");
    this.getLeaveCategory();
    this.getEmployeeDelegates("");
    this.getPaySchedule();
  },
};
</script>

<style>
.icon {
  width: 24px;
  height: 24px;
  padding: 3px;
}
.vdp-datepicker__calendar .cell {
  width: 13.285714% !important;
  height: 25px !important;
  line-height: 25px !important;
}

.vdp-datepicker__calendar {
  font-size: 12px !important;
  width: 100% !important;
}
textarea {
  width: 100%;
}
.cloudenly-input-container {
  display: block !important;
}
.count {
  top: 100% !important;
  left: 420px !important;
}
</style>
